export const ru = {
    add: 'Добавить',
    address: 'Адрес',
    cancel: 'Отмена',
    confirm: 'Уверены ли вы?',
    contract: 'Договор',
    email: 'Электронная почта',
    firstname: 'Имя',
    lang: "Ру",
    lastname: 'Фамилия',
    login: 'Вход',
    loginTitle: 'Войти в систему',
    name: 'Название',
    networkError: 'Ошибка подключения к серверу',
    no: 'Нет',
    yes: "Да",
    noData: 'Нет данных',
    noRoutes: 'Администратор не указал вам маршрут',
    ok: 'Да',
    password: 'Пароль',
    phone: 'Номер телефона',
    price: 'Цена',
    product: 'Продукт',
    registration: 'Регистратция',
    repeatThePassword: 'Повторите пароль',
    required: 'Пожалуйста, введите {{input}}!',
    role: 'Роль',
    save: 'Сохранить',
    store: 'Склад',
    update: 'Изменит',
    username: 'Имя пользователя',
    ROLE_ADMIN: "АДМИН",
    ROLE_USER: "ПОЛЬЗОВАТЕЛЬ",
    action: "Операции",
    search: "Поиск {{name}}",
    year: "Год",
    month: "Месяц",
    day: "День",
    hour: "Час",
    minute: "Минут",
    second: "Секунд",
    sectionAccess: "Доступ к разделу",
    delete: 'Удалить',
    select: 'Выбрать',
    from: 'из',
    to: 'до',
    selectPage: 'Выберите страницу',
    nds: 'НДС',
    noPermission: 'Нет разрешения',
    permission: 'Разрешение',
    empty: '(пусто)',
    fullName: 'Полное имя',
    users: 'Пользователи',
    region: 'Регион',
    inn: 'ИНН',
    code: "Код",
    mfo: 'МФО',
    hide: 'Скрыть',
    history: 'История',
    createdDate: 'Дата создания',
    size: 'Размер',
    download: 'Скачать',
    delete_confirm: 'Вы уверены, что хотите удалить ?',
    unset_account_confirm: 'Вы уверены, что хотите снять аккаунт?',
    status: 'Статус',
    bank: 'Банк',
    productName: 'Название продукта',
    invoiceNum: 'Номер счета',
    expirationDate: 'Срок действия',
    document: 'Документ',
    act: "Акт",
    supplier: "Поставщик",
    account: "Аккаунт",
    admin: "Админ",
    user: "Пользователь",
    filter: "Фильтр",
    branch: "Филиал",
    date: "Дата",
    id: "ID",
    total: "Итого",
    shipmentNumber: "Номер отгрузки",
    manualMatch: "Ручное сопоставление",
    accept: "Принять",
    info: "Информация",
    totalStart: "Сумма начало",
    totalEnd: "Сумма конец",
    supplierCode: "Код поставщика",
    supplierInn: "ИНН поставщика",
    branchCode: "Код филиала",
    isCancelled: "Отменен",
    isApplied: "Применен",
    isMatched: "Сопоставлен",
    isAccepted: "Принят",
    noSelect: "Не выбрано",
    dateStart: "Дата начало",
    dateEnd: "Дата конец",
    deliveryType: "Тип доставки",
    deliveryType_1: "Со склада на склад",
    deliveryType_2: "От продавца к покупателю",
    deliveryType_3: "Отдать на переработку",
    transportType: "Тип транспорта",
    roadway: "Перевозка автомобильным транспортом",
    airway: "Перевозка по авиа-линиям",
    railway: "Перевозка по железной дороге",
    seaway: "Перевозка по морским путям",
    executorInn: "ИНН поставщика",
    executorBranchCode: "Код филиала исполнителя",
    customerBranchCode: "Код филиала заказчика",
    responsiblePersonInn: "ИНН ответственного лица",
    number: "Номер",
    contractNumber: "Номер договора",
    contractDate: "Дата договора",
    createdAtStart: "Дата создания начальная",
    createdAtEnd: "Дата создания конечная",
    statuses: "Статусы",
    logout: "Выйти",
    documents: "Документы",
    roamingDocumentNumber: "Номер документа",
    roamingDocumentDate: "Дата документа",
    executorName: "Имя поставщика",
    roamingDocumentStatusSigned: "Подписан",
    roamingDocumentStatusSent: "Отправлено",
    roamingDocumentStatusCanceled: "Отменён",
    roamingDocumentStatusDeclined: "Отклонён",
    roamingDocumentStatusAccepted: "Подтвердён",
    roamingDocumentStatusRevoked: "Аннулирован",
    summa: "Сумма",
    type_0: "Стандартный",
    type_1: "Дополнительный",
    type_4: "Исправительный",
    singleSidedType_0: "Нет",
    singleSidedType_1: "На физ. лицо",
    type: "Тип",
    singleSidedType: "Односторонний",
    showIncomeAct: "Показать акт",
    showMatchDocument: "Показать документ",
    moreDetails: "Подробнее",
    matchedOn: "Сопоставлено",
    accepted: "Подписан",
    matchedBy: "Сопоставил",
    signedBy: "Сопоставил",
    bySystem: "Системой",
    certificate: "Сертификат",
    selectCertificate: "Выберите сертификат",
    invalidPassword: "Неверный пароль",
    canceled: "Ошибка при активации сертификата или отмене",
    actAccepted: "Принятые акты",
    successfully: "Успешно",
    matchDocument: "Сопоставленный документ",
    matchedOnStart: "Дата сопоставлено начало",
    matchedOnEnd: "Дата сопоставлено конец",
    acceptedOnStart: "Дата принято начало",
    acceptedOnEnd: "Дата принято конец",
    acceptAll: "Принять все",
    acceptSelected: "Принять выбранные",
    matchType: "Тип сопоставления",
    matchTypeSoft: "Автопроверка",
    matchTypeHalf: "Полупроверка",
    matchTypeDateDifferent: "Разница в датах",
    matchTypeNumberDifferent: "Разница в номерах",
    acceptAccount: "Кем принят",
    returnAct: "Возврат акта",
    returnActAccepted: "Принятые возврат акта",
    load: "Загрузить",
    enterActNumber: "Введите номер прихода",
    parameter: "Параметр",
    col1: "Акт и SAP",
    col2: "ЭСФ",
    agree: "СОГЛАСЕН С РИСКОМ, ХОЧУ ПОДПИСАТЬ ДОКУМЕНТ",
    innNotMatch: "ИНН не совпадает",
    matched: "Сопоставлено",
    subscribe: "Подписать",
    noCertificate: "Нет сертификата",
    selectSumma: "Общая сумма по выбранному",
    accountId: "Пользователь",
}