import React, {lazy} from 'react';
import {AppstoreOutlined,} from '@ant-design/icons';


const Status404 = lazy(() => import('./views/pages/Status404'));
const Status403 = lazy(() => import('./views/pages/Status403'));
const Status500 = lazy(() => import('./views/pages/Status500'));
const Supplier = lazy(() => import('./views/supplier/Supplier'));
const Account = lazy(() => import('./views/account/Account'));
const Document = lazy(() => import('./views/document/Document'));
const Act = lazy(() => import('./views/act/Act'));
const ReturnAct = lazy(() => import('./views/return-act/ReturnAct'));

const homeRoutes = [
    {key: '4', name: 'act', to: '/', component: Act, icon: <AppstoreOutlined/>, type: true},
    {key: '5', name: 'actAccepted', to: '/act', component: Act, icon: <AppstoreOutlined/>, type: true},
    {type: 'divider'},
    {key: '6', name: 'returnAct', to: '/return-act', component: ReturnAct, icon: <AppstoreOutlined/>, type: true},
    {key: '7', name: 'returnActAccepted', to: '/return-act-accepted', component: ReturnAct, icon: <AppstoreOutlined/>, type: true},
    {type: 'divider'},
    {key: '3', name: 'document', to: '/document', component: Document, icon: <AppstoreOutlined/>, type: true},
    {key: '1', name: 'supplier', to: '/supplier', index: true, component: Supplier, icon: <AppstoreOutlined/>, type: true},
    {key: '2', name: 'account', to: '/account', component: Account, icon: <AppstoreOutlined/>, type: true, role: 1},
    {key: '404', role: true, to: '/404', component: Status404, type: false},
    {key: '403', role: true, to: '/403', component: Status403, type: false},
    {key: '500', role: true, to: '/500', component: Status500, type: false},
];
export default homeRoutes;
