import React from 'react';
import {Route, Routes} from "react-router-dom";
import Login from "./views/login/Login";
import homeRoutes from "./homeRoutes";
import Status404 from "./views/pages/Status404";
import HomeLayout from "./layout/HomeLayout";
import {useAuth} from "./config/AuthProvider";

const Router = ({dark}) => {

    const {userData} = useAuth()

    return (
        <Routes>
            <Route key={"login"} path={'/login'} element={<Login dark={dark}/>}/>
            <Route key={"homeLayout"} path={"/"}
                   element={<HomeLayout dark={dark} routes={homeRoutes} home={false}/>}>
                {
                    homeRoutes ? homeRoutes?.map(i =>
                        i?.child ?
                            i?.child?.map(j => (!i.role || i.role === userData.type) ?
                                <Route key={j.key} path={j.to} element={<j.component dark={dark}/>}/> : undefined) :
                            (!i.role || i.role === userData.type) && i.key ?
                            <Route index={i.index} key={i.key} path={i.to}
                                   element={<i.component dark={dark}/>}/> : undefined ) : undefined
                }
                <Route path={"*"} element={<Status404 dark={dark}/>}/>
            </Route>
        </Routes>
    );
};

export default Router;