import React, {useContext, useEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {Button, Card, Form, message, Select} from "antd";
import MyModal from "../../component/Modal";
import {useTranslation} from "react-i18next";
import {CertificatePfx, eimzo} from "./eimzo";
import {MyContext} from "../../layout/HomeLayout";

const Signature = () => {

    const ref = useRef();
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [certificates, setCertificates] = useState([]);
    const [certificatesData, setCertificatesData] = useState([]);
    const [pfxCertificate, setPfxCertificate] = useState(null);

    const {certificate, setCertificate} = useContext(MyContext)

    useEffect(() => {
        eimzo.getCertificates().then(certificatesData => {
            console.log(certificatesData)
            setCertificatesData(certificatesData);
            setCertificates(certificatesData.map(certificate => {
                const pfxCertificate = new CertificatePfx(certificate);
                return {
                    innNumber: pfxCertificate.innNumber,
                    pinfl: pfxCertificate.pinfl,
                    companyName: pfxCertificate.companyName,
                    issuedPerson: pfxCertificate.issuedPerson,
                    validEndDate: pfxCertificate.validEndDate,
                    validFromDate: pfxCertificate.validFromDate,
                    value: `${certificate.disk}${certificate.alias}`,
                    isDisabled: pfxCertificate.validEndDate.getTime() <= +dayjs(),
                    isExpired: pfxCertificate.validEndDate.getTime() <= +dayjs()
                };
            }))
        })
    }, []);

    const onChange = async (option) => {
        if (option) {
            const certificate = certificatesData.find(certificate => `${certificate.disk}${certificate?.alias}` === option) || null;
            await eimzo.activate(certificate).then(async (activatedCertificate) => {
                const pfxCertificate = new CertificatePfx(certificate);
                setCertificate(activatedCertificate)
                setPfxCertificate(pfxCertificate)
                ref?.current?.closeModal();
            }).catch(e => {
                console.log(e)
                message?.open({
                    content: t(e?.invalidPassword ? 'invalidPassword' : 'canceled'),
                    key: 'updatable',
                    type: 'error'
                })
            });
        } else {
            setPfxCertificate(null)
            setCertificate(false)
        }
    }

    const filterOption = (input, option) => {
        const cert = option.cert;
        const inputLower = input.toLowerCase();

        return (
            cert.innNumber.toLowerCase().includes(inputLower) ||
            cert.pinfl.toLowerCase().includes(inputLower) ||
            cert.companyName.toLowerCase().includes(inputLower) ||
            cert.issuedPerson.toLowerCase().includes(inputLower)
        );
    };

    return (
        <>
            <MyModal modalTitle={t('certificate')} button={false} ref={ref} afterClose={() => {
                if(!pfxCertificate && !certificate) form.resetFields()
            }}>
                <Card bordered={false}>
                    <Form form={form} layout={'vertical'}>
                        <Form.Item name={"certificate"}>
                            <Select
                                style={{width: '100%', height: 'auto'}}
                                placeholder="Выберите сертификат"
                                allowClear
                                onChange={onChange}
                                showSearch
                                filterOption={filterOption}
                                dropdownRender={(menu) => (
                                    <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                        {menu}
                                    </div>
                                )}
                                options={certificates.map(cert => ({
                                    value: cert.value,
                                    label: (
                                        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            <div><strong>ИНН:</strong> {cert.innNumber}</div>
                                            <div><strong>ПИНФЛ:</strong> {cert.pinfl}</div>
                                            <div><strong>Ф.И.О.:</strong> {cert.issuedPerson}</div>
                                            <div><strong>Организация:</strong> {cert.companyName}</div>
                                            <div>{`От ${dayjs(cert.validFromDate).format('DD.MM.YYYY')} До ${dayjs(cert.validEndDate).format('DD.MM.YYYY')}`}</div>
                                            {cert.isExpired && (
                                                <div
                                                    style={{color: 'red'}}>{`Срок действия истек ${dayjs(cert.validEndDate).format('DD.MM.YYYY')}`}</div>
                                            )}
                                        </div>
                                    ),
                                    // disabled: cert.isDisabled,
                                    cert,
                                }))}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </MyModal>
            <Button size={"large"}
                onClick={() => ref?.current?.openModal()}>{!certificate ? t('selectCertificate') : (pfxCertificate?.companyName || pfxCertificate?.issuedPerson)?.toUpperCase()}</Button>
        </>
    );
};

export default Signature;
