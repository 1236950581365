import React, {createContext, Suspense, useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Flex, Layout, Skeleton} from "antd";
import {LoginOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined,} from '@ant-design/icons';
import {Outlet, useNavigate} from "react-router-dom";
import ThemeProvider from "../component/ThemeProvider";
import Sidebar from "./Sidebar";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import useCollapsedMode from "../config/useCollapsedMode";
import useSWRMutation from "swr/mutation";
import {logout} from "../api/api";
import {afterRequest, postRequest} from "../api/config";
import {useAuth} from "../config/AuthProvider";
import Signature from "../component/plugins/Signature";

const {Content, Header} = Layout

export const MyContext = createContext();

const HomeLayout = ({dark, routes, home = false}) => {

    const {userData} = useAuth()
    const {t} = useTranslation()
    const ref = useRef()

    const navigate = useNavigate()
    const {collapsed, changeCollapsed} = useCollapsedMode()

    const {trigger} = useSWRMutation(logout, postRequest)

    const [certificate, setCertificate] = useState(false)

    const logOut = () => {
        afterRequest(trigger, {}, () => navigate("/login"))
    }

    useEffect(() => {
        if (!localStorage.getItem("authority")) navigate("/login")
    })

    const items = [
        {key: "2", label: t('logout'), icon: <LoginOutlined/>, onClick: () => logOut()}
    ]

    return (
        <MyContext.Provider value={{certificate, setCertificate}}>
            <HelmetProvider>
                <Helmet>
                    <title>{userData.fullName || "Invoice-match"}</title>
                </Helmet>
                <ThemeProvider dark={dark}>
                    <Flex>
                        <Sidebar ref={ref} routes={routes} collapsed={collapsed} dark={dark} home={home}/>
                        <Layout style={{width: "calc(100% - 205px)"}}>
                            <Header style={{padding: 0, background: dark ? "#141414" : "#ffffff"}}>
                                <Flex justify={"space-between"} align={"center"}>
                                    <Button
                                        size={"large"}
                                        type="text"
                                        icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                        onClick={() => changeCollapsed()}
                                        style={{
                                            fontSize: '16px',
                                            width: 56,
                                            height: 56,
                                        }}
                                    />
                                    <Flex justify={"center"} gap={10} align={"center"} style={{marginRight: 16}}>
                                        <Signature/>
                                        <Dropdown menu={{items}} placement="bottom" trigger={['click']}>
                                            <Button size={"large"} danger icon={
                                                <UserOutlined/>}>{userData?.name || userData?.username || "User"}</Button></Dropdown>
                                    </Flex>
                                </Flex>
                            </Header>
                            <Content
                                style={{
                                    margin: '24px 16px',
                                    padding: 24,
                                    maxHeight: "calc(100vh - 104px)",
                                    overflowY: "auto",
                                    background: dark ? "#141414" : "#ffffff",
                                    borderRadius: 8
                                }}
                            >
                                <Suspense fallback={<Skeleton.Input block title paragrap style={{marginTop: 15}}/>}>
                                    <Outlet/>
                                </Suspense>
                            </Content>
                        </Layout>
                    </Flex>
                </ThemeProvider>
            </HelmetProvider>
        </MyContext.Provider>

    );
};

export default HomeLayout;