import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {Layout, Menu} from "antd";
import {Link, NavLink, useLocation} from "react-router-dom";
import ThemeProvider from "../component/ThemeProvider";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {getTranslateState} from "../component/component";
import {useAuth} from "../config/AuthProvider";
import Logo from "../component/Logo";
import classNames from "classnames";

const {Sider} = Layout

const Sidebar = forwardRef(({routes, collapsed, dark, home}, ref) => {

    const location = useLocation()
    const {t} = useTranslation()
    const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(ssd(location.pathname));
    const {userData} = useAuth()

    useImperativeHandle(ref, () => ({
        setSelectedKeysAndOpenKeysState(selectKeys, openKeys = []) {
            setSelectedKeysAndOpenKeysState(selectKeys, openKeys)
        },
    }))

    const setSelectedKeysAndOpenKeysState = (ssd, openKeys = []) => {
        setSelectedKeys(ssd)
        setOpenKeys(openKeys)
    }

    useEffect(() => {
        let loc = location.pathname.split("/")
        for (let n = 0; n < routes.length; n++) {
            if (routes[n].child) {
                for (let m = 0; m < routes[n].child.length; m++) {
                    let path = routes[n].child[m].to.split("/");
                    if (path[path.length - 1] === loc[loc.length - 1]) {
                        setOpenKeys([routes[n].key]);
                        break;
                    }
                }
            }
        }
        let abc = ssd(location.pathname)
        setSelectedKeys(abc)
        let rootSubmenuKeys = [];
        routes.filter(j => j).forEach(i => {
            if (i.child) {
                rootSubmenuKeys.push(i.key)
            }
        });
        setRootSubmenuKeys(rootSubmenuKeys);
    }, [location]);

    function ssd(location) {
        for (let n = 0; n < routes.length; n++) {
            let path;
            let loc = location.split("/");
            if (routes[n].child) {
                for (let m = 0; m < routes[n].child.length; m++) {
                    path = routes[n].child[m].to.split("/");
                    if (path[path.length - 1] === loc[loc.length - 1]) {
                        return `${routes[n].child[m].key}`;
                    }
                }
            } else {
                path = routes[n].to?.split("/");
                if (path && path[path.length - 1] === loc[loc.length - 1]) {
                    return `${routes[n].key}`;
                }
            }
        }
    }


    const menu = useMemo(() => {
        let menu = []
        if (!home) {
            routes.forEach((item) => {
                if (item.child) {
                    item.child.forEach((child) => {
                        if (menu.length === 0) {
                            menu.push({...item, child: [child]})
                        } else {
                            if (!menu.some(data => data.key === item.key)) {
                                menu.push({...item, child: [child]})
                            } else {
                                menu.forEach((dataChild) => {
                                    if (dataChild.key === item.key) {
                                        if (!dataChild.child?.some(some => some.key === child.key)) {
                                            dataChild.child?.push(child)
                                        } else if (!child.type) {
                                            dataChild?.child?.push(child)
                                        }
                                    }
                                })
                            }
                        }
                    })
                } else if (!item.role || item.role === userData.type || item.type === 'divider') {
                    menu.push(item)
                }
            })
        } else menu = routes
        menu = menu?.filter(i => (i))?.map(item => {
            if (item?.child) {
                return {
                    icon: item?.icon,
                    label: t(item?.name),
                    children: item?.child?.map(i => {
                        if (i?.type) {
                            return {
                                icon: i?.icon,
                                label: <NavLink to={i?.to}>
                                    {t(i?.name, i.state ? getTranslateState(t, i.state) : {})}
                                </NavLink>,
                                key: i.key
                            }
                        }
                    })
                }
            } else {
                if (item.type === 'divider') return item
                else if(item?.type) {
                    return {
                        icon: item?.icon,
                        label: <NavLink to={item.to}>
                            {t(item?.name, item.state ? getTranslateState(t, item.state) : {})}
                        </NavLink>,
                        key: item.key
                    }
                }
            }
        })
        return menu
    }, [routes, i18next.language])

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <ThemeProvider dark={dark}>
            <Sider
                style={{
                    height: "100vh",
                    background: dark ? "#141414" : "#fff",
                }}
                width={220}
                trigger={null}
                collapsed={collapsed}
            >
                <div className={classNames({
                    "pe-3": !collapsed,
                }, "demo-logo-vertical")} style={{
                    ...(collapsed ? {margin: "12px 9px"} : {margin: "12px 4px"}),
                    ...{background: dark ? "rgba(255, 255, 255, .2)" : "rgba(0, 0, 0, .2)"}
                }}>
                    <Link className={'shine'} to={'/'}><Logo height={26} width={32} sidebar={true}
                                                             collapsed={collapsed}/></Link>
                </div>
                <div className={"d-flex justify-context-center"}>
                    <Menu
                        style={{
                            overflowY: 'auto',
                            height: 'calc(100vh - 56px)',
                        }}
                        mode="inline"
                        selectedKeys={[selectedKeys]}
                        openKeys={openKeys}
                        defaultSelectedKeys={[selectedKeys]}
                        defaultOpenKeys={openKeys}
                        onOpenChange={onOpenChange}
                        items={menu}
                    />
                </div>
            </Sider>
        </ThemeProvider>
    );
})

export default Sidebar;