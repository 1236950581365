// export const URL = 'http://localhost:8080/api/';
export const URL = 'https://korzinka-match-esf.hippo.uz';

// ****************** AUTH ******************
export const login = '/auth/login';
export const logout = '/auth/logout';
// ****************** AUTH ******************

// ****************** USERS ******************
export const profile_data = '/auth/get-me';
// ****************** USERS ******************

// ****************** supplier ******************
export const supplier_all = '/api/supplier/all';
export const supplier_count = '/api/supplier/all/count';
export const supplier_add = '/api/supplier/register';
export const supplier_update = '/api/supplier/update/:id';
export const supplier_delete = '/api/supplier/delete/:id';
export const supplier_set_account = '/api/supplier/set-account/:id';
export const supplier_unset_account = '/api/supplier/unset-account/:id';
// ****************** supplier ******************

// ****************** account ******************
export const account_all = '/api/account/all';
export const account_add = '/api/account/register';
export const account_update = '/api/account/update/:id';
export const account_delete = '/api/account/delete/:id';
export const account_change_password = '/api/account/change-password/:id';
// ****************** account ******************

// ****************** income act ******************
export const income_act = '/api/income-act/all';
export const income_act_by_id = '/api/income-act/:id';
export const income_act_csv = '/api/income-act/csv';
export const income_act_count = '/api/income-act/all/count';
export const income_act_match = '/api/income-act/match/:id';
export const income_act_un_match = '/api/income-act/un-match/:id';
export const income_act_hashcode = '/api/income-act/hashcode/accept/:id';
export const income_act_accept = '/api/income-act/customer/accept/:id';
// ****************** income act ******************

// ****************** return act ******************
export const return_act = '/api/return-act/all';
export const return_act_by_id = '/api/return-act/:id';
export const return_act_csv = '/api/return-act/csv';
export const return_act_count = '/api/return-act/all/count';
export const return_act_match = '/api/return-act/match/:id';
export const return_act_un_match = '/api/return-act/un-match/:id';
export const return_act_hashcode = '/api/return-act/hashcode/accept/:id';
export const return_act_accept = '/api/return-act/customer/accept/:id';
// ****************** return act ******************

// ****************** roaming document ******************
export const roaming_document = '/api/roaming-document/all';
export const roaming_document_count = '/api/roaming-document/all/count';
// ****************** roaming document ******************
